.active {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1rem;
  top: 58.5px;
  padding: 2rem;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  max-width: var(--max-width);
}

.image {
  width: 100%;
  object-fit: contain;

  border-radius: 2px;
}

.headerSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.75rem;
}

.line {
  width: 100%;
  border: 0.5px solid var(--primary-border);
}

.header {
  color: black;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  column-gap: 0.5rem;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}

.brandVal {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.brandLogo {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.linkContainer {
  padding: 0.5rem;
  border: 1px solid var(--primary-bg);
  border-radius: 10px;
  text-decoration: none;
  position: relative;
  max-width: 33%;
  width: fit-content;
  overflow: hidden;
  max-height: 4rem;
}

.brandLogo:hover {
  transform: scale(1.1);
}

.clear {
  white-space: nowrap;
  text-decoration: none;
  font-size: 12px !important;
  color: var(--text-body);
  font-weight: 300;

  transition: all 0.1s ease-in-out;
}

.clear:hover {
  cursor: pointer;
  color: var(--text-main);
  text-decoration: underline;
}

.col {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.colWidth100 {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.colSpace0 {
  display: grid;
  /* 5 items same width */
  grid-template-columns: repeat(6, 1fr);

  column-gap: 0.5rem;
}

.badgeBox1 {
  width: 100%;
  display: flex;
  gap: 0.5rem;

  justify-content: flex-start;
  flex-wrap: wrap;
}

.badgeBox2 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.badge1 {
  background-color: var(--primary-bg);
  font-size: 14px;
  color: black;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 20px;
}
.badge1:hover {
  cursor: pointer;
  background-color: #e1e1e1;
}

.badge2 {
  background-color: var(--primary-bg);
  font-size: 14px;
  color: var(--text-body);
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 20px;
}
.badge2:hover {
  cursor: pointer;
  background-color: #e1e1e1;
}

.link {
  text-decoration: none;
  position: relative;
}

.searchVal {
  padding: 0.2rem 0.3rem;
  display: flex;
  flex-direction: column;
  color: black;
  font-size: 14px;
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin-bottom: 0rem;
  margin-left: 0.7em;
}

.searchVal:hover {
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--primary-bg);
}

.headerSectionJustify {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 0.5rem;
  align-items: center;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  right: 1rem;
  top: 1rem;
  background-color: var(--primary-bg);
  border-radius: 50%;
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  border: 1px solid var(--primary-border);
  color: var(--text-body);
  padding: 6px;
  transition: all 0.1s ease-in-out;
}

.closeIcon:hover {
  opacity: 0.7;
  color: var(--text-main);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1rem;
}

@media (max-width: 768px) {
  .active {
    top: 108.5px;
    padding: 1rem;
  }

  .header {
    font-size: 14px;
  }
  .row {
    flex-direction: column;
  }

  .badge1 {
    font-size: 12px;
  }

  .col {
    width: 100%;
  }

  .colWidth100 {
    margin-bottom: 1rem;
  }

  .clear {
    font-size: 14px;
  }

  .image {
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 2px;
  }

  .searchVal {
    padding: 0.2rem 0.3rem;
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 14px;
  }

  .colSpace0 {
    display: flex;
    flex-direction: column;
  }

  .closeIcon {
    right: 1rem;
    top: 1rem;
    width: 21px;
    height: 21px;
    min-width: 21px;
    min-height: 21px;
    padding: 4px;
  }

  .row {
    row-gap: 0.75rem;
    position: relative;
  }
}
