.main {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 32px;
  padding-top: 5rem;

  color: var(--text-main);
  text-align: center;
  margin-bottom: 5rem;
}

.soldTitle {
  margin-top: 2rem;
  margin-left: 12px;
  margin-right: auto;
  font-size: 20px;
  font-weight: 500;
  color: var(--text-main);
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

/* IMG */

.imgContainer {
  overflow: hidden;
  position: relative;
  margin: 1%;
  width: 23%;
  height: 15rem;
  border-radius: 5px;
}

.img {
  border-radius: 5px;
  filter: brightness(0.7);
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.2s linear;
}

.subTitle {
  text-transform: uppercase;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  position: absolute;
  color: white;
  font-weight: 600;
}

.img:hover {
  transition: 0.2s linear;
  transform: scale(1.1);
}

.separator {
  height: 2px;
  width: 30%;
  background-color: #555555;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .container {
    justify-content: center;
  }
  .imgContainer {
    width: 47%;
  }
}
